import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import "./pricing.scss";

import Button from "../components/button/button";
import SectionHeading from "../components/section-heading/section-heading";
import ContactSection from "../components/contact-section/contact-section";
import CappedWidth from "../components/capped-width/capped-width";

const PricingPage = () => (
  <Layout>
    <SEO title="Pricing" />

    <div className="section pricing" id="pricing">
      <CappedWidth>
        <h1 className="page-title">Pricing</h1>
        <h2 className="page-subtitle">
          Okay, it’s useful and unique, and I learned a bunch in my free month.
          How much does membership cost?{" "}
        </h2>
        <p>
          Your first month of membership is free. We figure many people need
          that month to figure out if the workshop is worth paying for. Our
          costs are as low as we can make them, and we offer three membership
          plans:
        </p>
        <div className="pricing-table-wrapper">
          <div className="pricing-table-body">
            <div className="pricing-table-column">
              <div className="pricing-table-cell heading">
                One Year <br />
                Paid annually
              </div>
              <div className="pricing-table-cell price">$49</div>
              <div className="pricing-table-cell description">
                94 cents a week! Much less than the cost of other workshops and
                online classes.
              </div>
              {/* <div className="pricing-table-cell choose-plan-button">
                <Button color="purple" text="Choose plan" />
              </div> */}
            </div>
            <div className="pricing-table-column dark">
              <div className="pricing-table-cell heading">
                One Year <br />
                Paid monthly
              </div>
              <div className="pricing-table-cell price">$6 / month</div>
              <div className="pricing-table-cell description">
                Some people prefer to pay a little each month. Still less than
                the cost of other workshops and online classes.
              </div>
              {/* <div className="pricing-table-cell choose-plan-button">
                <Button color="purple" text="Choose plan" />
              </div> */}
            </div>
            <div className="pricing-table-column">
              <div className="pricing-table-cell heading">Six Months</div>
              <div className="pricing-table-cell price">$30</div>
              <div className="pricing-table-cell description">
                About the price of a hardcover and a coffee. Works out to $1.15
                a week.
              </div>
              {/* <div className="pricing-table-cell choose-plan-button">
                <Button color="purple" text="Choose plan" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="pricing-table-buttons-wrapper">
          <Button
            color="purple"
            text="Start Your Free Trial"
            path="/free-trial.shtml"
          />
          <Button
            color="purple"
            fill
            text="Compare With Others"
            path="#value-comparison"
          />
        </div>
      </CappedWidth>

      <CappedWidth>
        <div className="pricing-table-notes">
          <p>
            {" "}
            By <a href="#how-to-pay">purchasing a membership</a>, you affirm
            that you have read and will abide by our{" "}
            <a
              href="/member-agreement"
              target="_blank"
              rel="noopener noreferrer"
            >
              Member Agreement
            </a>{" "}
            (really, you should read it).{" "}
          </p>
          <p>
            Your membership expiration date appears on your{" "}
            <a href="/app/ww/ww.cgi?:user_data">Dashboard</a> page. Two weeks
            before your membership expires, we will send you a renewal reminder
            by email.
          </p>
          <p>
            You can renew your membership by clicking on the link in that email
            or merely continuing with the type of membership you’d like on this
            page: you will be asked for your login information shortly!
          </p>{" "}
          <p>
            If you purchase a membership before the end of your one-month trial,
            your paid membership will begin only after your free month—you won’t
            lose the free time.{" "}
          </p>
          <p>
            Multiple memberships belonging to the same individual are not
            allowed.
          </p>
          <p>
            We welcome the participation of professional agents and
            magazine/book editors in the Online Writing Workshop for SF & F.
            Professional memberships are offered free of charge. See{" "}
            <a href="#professional-memberships">Professional Memberships</a>{" "}
            section for details.
          </p>
        </div>
      </CappedWidth>
    </div>

    <div className="section ways-to-support" id="more-ways-to-support-us">
      <CappedWidth>
        <SectionHeading>More Ways to Support Us</SectionHeading>
        {/* Scholarship Donations */}
        <div className="ways-to-support-item">
          <h3 className="way-to-support-title">Scholarship donations</h3>
          <h4 className="way-to-support-subtitle">
            Donate an extra $5 or more toward scholarships for active,
            review-contributing workshop members.
          </h4>
          <p className="way-to-support-description">
            Our scholarship fund provides free memberships or financial aid to
            active, review-contributing members who cannot pay the full fee
            themselves—for example, dedicated members in countries where
            exchange rates and the local value of the U.S. dollar place our fee
            completely out of reach.
          </p>
          <div className="way-to-support-button-wrapper">
            {/* Scholarship Donation Button */}
            <div className="way-to-support-button">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_blank"
              >
                <input type="hidden" name="cmd" value="_xclick" />
                <input
                  type="hidden"
                  name="business"
                  value="payments@onlinewritingworkshop.com"
                />
                <input
                  type="hidden"
                  name="item_name"
                  value="$5 or More Scholarship Donation"
                />
                <input
                  type="hidden"
                  name="item_number"
                  value="SFFHscholarship"
                />
                <input type="hidden" name="amount" />
                <input type="hidden" name="currency_code" value="USD" />
                <input
                  type="hidden"
                  name="return"
                  value="https://onlinewritingworkshop.com/"
                />
                <input
                  className="button purple"
                  type="submit"
                  value="Donate Scholarship"
                />
              </form>
            </div>
          </div>
        </div>
        {/* Gift Memberships */}
        <div className="ways-to-support-item">
          <h3 className="way-to-support-title">Gift memberships</h3>
          <h4 className="way-to-support-subtitle">
            Give another member a membership.
          </h4>
          <p className="way-to-support-description">
            To pay online, use the “gift membership” buttons below, or send
            <a href="#how-to-pay"> a check or money order</a>. Note in the
            PayPal online payment form, or on the check or money order, that the
            membership is a gift and who it’s for (username or member name). Let
            us know if you want to remain anonymous. We will acknowledge your
            gift as well as notifying the recipient.
          </p>
          <div className="way-to-support-button-wrapper">
            {/* 1-year membeship gift button  */}
            <div className="way-to-support-button">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_blank"
              >
                <input type="hidden" name="cmd" value="_xclick" />
                <input
                  type="hidden"
                  name="business"
                  value="payments@onlinewritingworkshop.com"
                />
                <input
                  type="hidden"
                  name="item_name"
                  value="1-year Gift Membership"
                />
                <input
                  type="hidden"
                  name="item_number"
                  value="SFFHscholarship"
                />
                <input type="hidden" name="amount" value="49.00" />
                <input type="hidden" name="currency_code" value="USD" />
                <input
                  type="hidden"
                  name="return"
                  value="https://onlinewritingworkshop.com/"
                />
                <input
                  className="button purple"
                  type="submit"
                  value="Gift a 1-year Membership"
                />
              </form>
            </div>
            {/* 6-month membership gift button  */}
            <div className="way-to-support-button">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_blank"
              >
                <input type="hidden" name="cmd" value="_xclick" />
                <input
                  type="hidden"
                  name="business"
                  value="payments@onlinewritingworkshop.com"
                />
                <input
                  type="hidden"
                  name="item_name"
                  value="6-month Gift Membership"
                />
                <input type="hidden" name="item_number" value="SFFH6gift" />
                <input type="hidden" name="amount" value="30.00" />
                <input type="hidden" name="currency_code" value="USD" />
                <input
                  type="hidden"
                  name="return"
                  value="https://onlinewritingworkshop.com/"
                />
                <input
                  className="button purple"
                  type="submit"
                  value="Gift a 6-month Membership"
                />
              </form>
            </div>
          </div>
        </div>
        {/* Bonus Payment */}
        <div className="ways-to-support-item">
          <h3 className="way-to-support-title">Bonus payment</h3>
          <h4 className="way-to-support-subtitle">
            If you feel that what you get from the workshop is worth more than
            94 cents per week, tip us!
          </h4>
          <p className="way-to-support-description">
            We agree that 94 cents per week ($49 per year) is a piddling sum.
            It’s the least we could charge and still expect to stay solvent. But
            we know that many members feel that the workshop is worth much more
            to them. So here’s your chance to award us with a bonus on top of
            your membership fee. 25% of any bonus payments go to our support
            staff, sort of like a tip for good personal service. The rest will
            be tucked away to keep us running!
          </p>
          {/* Bonus Payment Button */}
          <div className="way-to-support-button">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank"
            >
              <input type="hidden" name="cmd" value="_xclick" />
              <input
                type="hidden"
                name="business"
                value="payments@onlinewritingworkshop.com"
              />
              <input
                type="hidden"
                name="item_name"
                value="Bonus Payment for OWW-SFFH"
              />
              <input type="hidden" name="item_number" value="SFFHBonus" />
              <input type="hidden" name="currency_code" value="USD" />
              <input
                type="hidden"
                name="return"
                value="https://onlinewritingworkshop.com/"
              />
              <input
                className="button purple"
                type="submit"
                value="Gift a Bonus Payment"
              />
            </form>
          </div>
        </div>
      </CappedWidth>
    </div>

    <div className="section value-comparison" id="value-comparison">
      <CappedWidth>
        <SectionHeading>Price & Value Comparisons</SectionHeading>
        <p>
          This table compares our workshop with other online learning
          opportunities for writers. We’ve included the links because we think
          when you compare, you’ll find the best value here. But if it’s
          someplace else, we wish you good luck.
        </p>
      </CappedWidth>
      <div className="comparison-table-wrapper">
        <div className="comparison-table">
          <div className="comparison-table-header">
            <div className="comparison-table-cell header">Program</div>
            <div className="comparison-table-cell header">Length</div>
            <div className="comparison-table-cell header">Cost</div>
            <div className="comparison-table-cell header">Cost/Week</div>
            <div className="comparison-table-cell header">SF & F Focus</div>
            <div className="comparison-table-cell header">What you get</div>
          </div>
          <div className="comparison-table-body">
            <div className="comparison-table-row">
              <div className="comparison-table-cell program">
                <Link to="/">Online Writing Workshop</Link>
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Length</span>One year
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost</span>$49
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost/Week</span>94 cents
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">SF & F Focus?</span>Nothing
                but.
              </div>
              <div className="comparison-table-cell what-you-get">
                {" "}
                <span className="header-item-mobile">What You Get</span>
                Submit three pieces at a time for critique; review to learn;
                Editors' Choice reviews by pros in the field; writers’
                discussion groups; resources, tips and advice
              </div>
            </div>
            <div className="comparison-table-row dark">
              <div className="comparison-table-cell program">
                <a
                  href="https://www.writingclasses.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gotham Writer's Workshop
                </a>
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Length</span>10 weeks
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost</span>
                $399 plus registration fee
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost/Week</span>$39.90
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">SF & F Focus?</span>One
                class on SF & F
              </div>
              <div className="comparison-table-cell what-you-get">
                <span className="header-item-mobile">What You Get</span>
                Individual classes on specialized topics: fiction writing, novel
                writing, etc. Some include exercises, lectures, critique of one
                work
              </div>
            </div>
            <div className="comparison-table-row">
              <div className="comparison-table-cell program">
                <a
                  href="https://www.writersonlineworkshops.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Writers Digest University
                </a>
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Length</span>6-10 weeks
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost</span>$290-$335
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost/Week</span>
                $48.33-$33.50
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">SF & F Focus?</span>No
              </div>
              <div className="comparison-table-cell what-you-get">
                <span className="header-item-mobile">What You Get</span>
                Individual classes on specialized topics: fiction fundamentals,
                novel writing, etc.
              </div>
            </div>
            <div className="comparison-table-row dark">
              <div className="comparison-table-cell program">
                <a
                  href="https://www.coffeehouseforwriters.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Coffee House for Writers
                </a>
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Length</span>4 weeks
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost</span>$89
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost/Week</span>$22.25
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">SF & F Focus?</span>No
              </div>
              <div className="comparison-table-cell what-you-get">
                <span className="header-item-mobile">What You Get</span>
                Individual classes on specialized topics: creativity, story
                structure, etc.
              </div>
            </div>
            <div className="comparison-table-row">
              {" "}
              <div className="comparison-table-cell program">
                <a
                  href="https://writersvillage.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Writers Village University
                </a>
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Length</span>1 week to 1
                year
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost</span>$99/year
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost/Week</span>varies
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">SF & F Focus?</span>
                Occasional class on SF & F
              </div>
              <div className="comparison-table-cell what-you-get">
                <span className="header-item-mobile">What You Get</span>
                Short classes on specific writing topics: dialogue,
                characterization, etc. Most are 3-6 weeks. Ongoing workshops.
              </div>
            </div>
            <div className="comparison-table-row dark">
              <div className="comparison-table-cell program">
                <a
                  href="https://www.critters.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Critters
                </a>
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Length</span>Ongoing
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost</span>Free
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost/Week</span>$0
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">SF & F Focus?</span>Yes
              </div>
              <div className="comparison-table-cell what-you-get">
                <span className="header-item-mobile">What You Get</span>
                Mailing list system for submitting and critiqung other's work;
                approx. one-month waiting list to submit; must remain active
              </div>
            </div>
            <div className="comparison-table-row">
              {" "}
              <div className="comparison-table-cell program">
                <a
                  href="https://www.reddit.com/r/fantasywriters/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Reddit
                </a>
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Length</span>Ongoing
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost</span>Free
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">Cost/Week</span>$0
              </div>
              <div className="comparison-table-cell">
                <span className="header-item-mobile">SF & F Focus?</span>Yes
              </div>
              <div className="comparison-table-cell what-you-get">
                <span className="header-item-mobile">What You Get</span>
                Leaderless online craft discussion and work critique; no
                protection of first publication rights.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="section how-to-pay" id="how-to-pay">
      <CappedWidth>
        <SectionHeading>How to Pay</SectionHeading>
        <h3>
          You can send us payments via PayPal or through the mail with a check
          or money order.
        </h3>
        {/* Paypal */}
        <div className="how-to-pay-item">
          <h3 className="how-to-pay-title">Paypal</h3>
          <p className="how-to-pay-description">
            You can conveniently pay for your membership or make a donation via
            Paypal. To pay for a membership via paypal, see{" "}
            <a href="#pricing">Pricing</a> section and select a plan of your
            choice through your{" "}
            <a href="/app/ww/ww.cgi?:user_data">Dashboard</a>. To make a
            donation via paypal, see{" "}
            <a href="#more-ways-to-support-us">More Ways to Support Us</a>{" "}
            section above and choose your supporting payment of preference.
          </p>
        </div>
        {/* Checks */}
        <div className="how-to-pay-item">
          <h3 className="how-to-pay-title">Checks</h3>
          <p className="how-to-pay-description">
            We accept checks drawn on U.S. banks only, made out to Online
            Writing Workshops. (Bigger banks outside the U.S. can issue you a
            bank check drawn on a U.S. bank.) We only accept 1-year membership
            payments using this method. Put “<b>OWW-SFFH 1-year</b>” and{" "}
            <b>your username</b> on your check.
          </p>
        </div>
        {/* Money Order */}
        <div className="how-to-pay-item">
          <h3 className="how-to-pay-title">Money Order</h3>
          <p className="how-to-pay-description">
            We accept U.S. and international money orders in U.S. dollars. (Post
            offices and some banks sell international money orders.) We only
            accept 1-year membership payments using this method. Put “
            <b>OWW-SFFH 1-year</b>” and <b>your username</b> on the money order.
          </p>
        </div>
        {/* check and money order instructions */}
        <p className="how-to-pay-description">
          <b>Send $49* check or money order to:</b>
          <br />
          Online Writing Workshops
          <br /> 229 Bradley’s Crossing Road, Stop 19
          <br /> East Chatham, New York 12060 USA
          <br />
          <br /> *If you live in New York State, you need to pay sales tax
          (because we have to pay it to the state): your check should be for
          $52.92.
        </p>

        <p className="how-to-pay-notes">
          If you pay by PayPal, or money order, we will update your membership
          within three business days of receipt. If you pay by check, we will
          update your membership expiration date as soon as we receive your
          check and the check clears our bank. Clearing could take up to seven
          business days, so don’t wait until the last minute! Thanks for joining
          us! We appreciate your support.
        </p>
        <h3 className="refunds">Refunds</h3>
        <p>
          Refunds will be made, prorated by month, under certain very limited
          circumstances. Unfortunately, we cannot offer refunds at the
          conclusion of membership periods. Refunds will be made at the sole
          discretion of the Online Writing Workshops staff. Use our{" "}
          <a href="#contact">contact form</a> to request a refund, giving us
          your name, your username, and a clear explanation of why you are
          requesting a refund.
        </p>
      </CappedWidth>
    </div>

    <div
      className="section professional-memberships"
      id="professional-memberships"
    >
      <CappedWidth>
        <SectionHeading>Professional Memberships</SectionHeading>
        <h3>
          We welcome the participation of professional agents and magazine/book
          editors in the Online Writing Workshop for SF & F. Professional
          memberships are offered free of charge.
        </h3>
        <div className="professional-memberships-content">
          <p>
            {" "}
            Because OWW's primary concern is to look out for the interests of
            our author members, we do have a few conditions upon your membership
            as an agenting professional:{" "}
          </p>
          <br />
          <h3 className="professional-memberships-title">
            If You Are an Agent
          </h3>
          <p>
            If you are an agent, you need to request a special free professional
            membership on letterhead via US Mail{" "}
            <a href="#mailing-address">to our mailing address</a>. Include the
            following information:{" "}
          </p>
          <ul>
            <li>Name of the agency</li>
            <li>How long the agency has been in business</li>
            <li>Agency membership in AAR?</li>
            <li>Location of the agency or main office of the agency</li>
            <li>
              Some recent sales (lengths, genres, authors, titles, publishers)
            </li>
            <li>
              Other services offered besides representing authors to publishers
              (manuscript reading for a fee, editing for a fee, manuscript
              preparation services, etc.)
            </li>
            <li>
              If you do offer other services, % of your agency's income that
              comes from those services and percentage that comes from
              commissions on sales to publishers
            </li>
          </ul>
          <p>
            You are probably aware of the many scam artists calling themselves
            "agents," and can understand why we are interested in the
            credentials of your agency.
          </p>
          <br />
          <h3 className="professional-memberships-title">
            If You Are a Professional Editor
          </h3>
          <p>
            If you are a professional editor at a SFWA-recognized magazine or
            book publisher, send us the following information either by US Mail{" "}
            <a href="#mailing-address">to our mailing address </a>
            or via our <a href="#contact">contact form</a>:
          </p>
          <ul>
            <li>Name of your publisher or publication</li>
            <li>How long you have worked/freelanced there </li>
            <li>
              How frequently you buy work from new authors in the SF/F field{" "}
            </li>
          </ul>
          <br />
          <div className="professional-memberships-notes">
            <p>
              Your OWW Member Directory entry must clearly state your
              professional status (editor, agent, manuscript reviewer, etc.) and
              who you work for. Contact information is allowed in your Member
              Directory listing as well. OWW will approve Member Directory
              entries from all professional members to ensure accurate
              representation.
            </p>
            <p>
              We will eventually be adding to our site a listing of workshop
              members who are publishing professionals. If you apply for our
              professional membership, we will be displaying your
              member-directory entry in that list.
            </p>
            <p>
              Any reviews you contribute must include the same accurate
              representation of your professional status as your Member
              Directory entry.
            </p>
            <p>
              Reviews may not include direct solicitations to members (those may
              be made through e-mail to members who list their addresses).
            </p>
            <p>
              Finally, if an author you are interested in has no listed email
              address, contact us and we will forward a message from you to the
              author.{" "}
            </p>
            <p>
              As long as you are willing to comply with these simple requests
              and provide us with the information we need, you are welcome in
              the workshop. We are confident that you'll find a lot of great
              writing featured here! If you have questions, feel free to{" "}
              <a href="#contact">contact us</a>.
            </p>
            <p id="mailing-address">
              <b>OWW mailing address:</b>
              <br />
              Online Writing Workshops
              <br />
              229 Bradley's Crossing Road, Stop 19 <br />
              East Chatham, New York 12060 USA
              <br />
            </p>
          </div>
        </div>
      </CappedWidth>
    </div>
    <div id="contact">
      <ContactSection />
    </div>
  </Layout>
);

export default PricingPage;
