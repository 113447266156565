import React, { useState, useEffect } from "react";
import "./contact-section.scss";
import SectionHeading from "../section-heading/section-heading";
import Button from "../button/button";

function useInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  function handleChange(e) {
    setValue(e.target.value);
  }
  function clear() {
    setValue("");
  }
  return [value, handleChange, clear];
}

const ContactSection = () => {
  const [name, setName, clearName] = useInput("");
  const [email, setEmail, clearEmail] = useInput("");
  const [message, setMessage, clearMessage] = useInput("");
  const [emailValid, setEmailValid] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (/.+\@.+\..+/.test(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }, [email]);

  const FORM_SUBMIT_PATH = "/app/formcruncher.cgi";

  function handleSubmit(e) {
    e.preventDefault(); // stops default reloading behaviour
    const formData = new URLSearchParams();
    formData.append("0name", name);
    formData.append("1email", email);
    formData.append("2message", message);
    formData.append("_template", "contact");
    formData.append("Subject", "(From the SFF Workshop)");
    formData.append("_required", "0name,1email,2message");
    formData.append("_thanks", "/contact_thanks.shtml");
    fetch(FORM_SUBMIT_PATH, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.text().then((parsed) => {
          // we don't want to catch the default
          // response that the server is giving, it's not a real error for us
          // we DO want to catch other errors, though they are unlikely, since client side validation is happening
          // first
          if (parsed.includes("error") && !parsed.includes("The script was instructed to display a page")) {
            throw new Error();
          }
        });
      })
      .then(() => {
        setSuccess(true);
        clearName();
        clearEmail();
        clearMessage();
        setTimeout(() => {
          setSuccess(false);
        }, 10000);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  }

  const formNotValid =
    name.length === 0 ||
    email.length === 0 ||
    (email.length > 0 && !emailValid) ||
    message.length === 0;

  return (
    <div className="section got-questions">
      <div className="capped-width">
        <SectionHeading>Got Questions?</SectionHeading>
        <p>
          Let us know if you find a problem with the workshop, or just ask
          questions not answered on the site. We’ll try to get back to you
          within a few days—a week at the longest. If you’d rather email us
          directly, send a message to{" "}
          <a href="mailto:support@onlinewritingworkshop.com">
            support@onlinewritingworkshop.com
          </a>
          .
        </p>

        <div className="contact-form-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="contact-form-name-email-wrapper">
              <div className="contact-form-input-wrapper left-input">
                <label htmlFor="fname">Your Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Ollie Writter"
                  value={name}
                  onChange={setName}
                />
              </div>
              <div className="contact-form-input-wrapper">
                <label htmlFor="email">Your Email Address</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="olliewritter@domain.com"
                  value={email}
                  onChange={setEmail}
                />
                {email.length > 0 && !emailValid && (
                  <div className="email-invalid">Not a valid email address</div>
                )}
              </div>
            </div>
            <div className="contact-form-input-wrapper">
              <label htmlFor="message">Your Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Type your message here"
                value={message}
                onChange={setMessage}
              ></textarea>
            </div>
            <Button text="Send Message" fill submit disabled={formNotValid} />

            {success && (
              <div className="contact-success">
                Your message has been sent. Thanks for reaching out!
              </div>
            )}
            {error && (
              <div className="contact-error">
                There was an error sending your message.
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
